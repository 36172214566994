import { createTheme } from '@mui/material/styles';

/*
Hex codes for theme
Gunmetal: #2c363f
Blush: #e75a7c
Ivory: #f2f5ea
Timberwolf: #d6dbd2
Turquoise: #5ae7c6
Error Yellow: #E7C65A
*/
const theme = createTheme({
    // palette:{
    //     common:{
    //         black:"rgba(0, 0, 0, 1)",
    //         white:"rgba(255, 255, 255, 1)"
    //     },
    //     background:{
    //         paper:"rgba(44, 54, 63, 1)",
    //         default:"rgba(44, 54, 63, 1)"
    //     },
    //     primary:{
    //         light:"rgba(255, 103, 140, 1)",
    //         main:"rgba(231, 90, 124, 1)",
    //         dark:"rgba(188, 73, 101, 1)",
    //         contrastText:"rgba(242, 245, 234, 1)"
    //     },
    //     secondary:{
    //         light:"rgba(98, 255, 218, 1)",
    //         main:"rgba(90, 231, 198, 1)",
    //         dark:"rgba(68, 177, 151, 1)",
    //         contrastText:"rgba(44, 54, 63, 1)"
    //     },
    //     error:{
    //         light:"rgba(254, 218, 99, 1)",
    //         main:"rgba(231, 198, 90, 1)",
    //         dark:"rgba(194, 168, 78, 1)",
    //         contrastText:"rgba(44, 54, 63, 1)"
    //     },
    //     text:{
    //         primary:"rgba(242, 245, 234, 1)",
    //         secondary:"rgba(90, 231, 198, 1)",
    //         disabled:"rgba(0, 0, 0, 1)",
    //         hint:"rgba(214, 219, 210, 1)"
    //     }
    // }
    palette: {
        type: 'dark',
        text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)'
        },
        background: {
            default: '#303030',
            paper: '#424242'
        },
        primary: {
        //   main: '#5AE7C6',
          main: '#9792E3',
        },
        secondary: {
          main: '#9792E3',
        },
        error: {
          main: '#EE6C4D',

          /*EE6C4D
          E85D75*/
        },
        info: {
            main: '#6248B8'
        }
    },
})

export default theme;
