import axios from "axios"
import baseURL from "../api/api"

function newAbortSignal(timeoutMs) {
    const abortController = new AbortController()
    setTimeout(() => abortController.abort(), timeoutMs || 0)

    return abortController.signal
}

export const api = axios.create({
//   baseURL: process.env.REACT_APP_SERVER_URL,
  baseURL: baseURL,
//   withCredentials: true,
})

export const axiosPrivate = axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json'},
    withCredentials: true
})

export function makeRequest(url, options) {
    const timeoutTime = 5000 // 5 seconds
  return api(url, options, {signal: newAbortSignal(timeoutTime)})
    .then(res => res.data)
    .catch(error => {
        return Promise.reject(error?.response?.data?.message ?? "Error")})
}

export function makePrivateRequest(url, options) {
    return axiosPrivate(url, options)
    .then((res) => res.data)
    .catch(error => {
        return Promise.reject(error?.response?.data?.message ?? "Error")
    })
}
