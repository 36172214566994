import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import HomeIcon from '@mui/icons-material/Home'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Breadcrumbs from './Breadcrumbs'
import { useNavigate } from 'react-router-dom'
import UserAvatar from './UserAvatar'
import { useAuth } from '../../contexts/AuthContext'
import useLogout from '../../hooks/useLogout'

export default function BarMenu({ removeToken }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const navigate = useNavigate()
    const { auth } = useAuth()
    const logout = useLogout()

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    function handleProfileClick() {
        navigate(`/User Profile`)
        handleClose()
    }

    async function handleLogout() {
        await logout().then(() => {
            navigate('/')
        })
    }

    return (
        <Box sx={{ mb: 3, flexGrow: 1, backgroundColor: 'background.default' }}>
            <AppBar
                position="static"
                top="10px"
                color="transparent"
                sx={{ maxHeight: { sm: 55 }, width: '100%' }}
            >
                <Toolbar sx={{ minHeight: { sm: 50 } }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => navigate('/ports')}
                    >
                        <HomeIcon />
                    </IconButton>
                    <Breadcrumbs />
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <UserAvatar
                                firstName={auth?.firstName}
                                lastName={auth?.lastName}
                                bar={40}
                            />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
