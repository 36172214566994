import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import useTheme from '@mui/material/styles/useTheme'

export default function Missing() {
    const theme = useTheme()
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                width: '100%',
                height: '60vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}
        >
            <Box>
                <Typography color={theme.palette.error.main} variant="h1">
                    Oops!
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '80%',
                }}
            >
                <Typography
                    sx={{
                        textAlign: 'center',
                    }}
                    color={theme.palette.text.primary}
                >
                    The page you have requested was not found. Use the button below to
                    navigate to the homepage.
                </Typography>
            </Box>
            <Box>
                <Button onClick={() => navigate('/')} variant="contained">
                    Back to Homepage
                </Button>
            </Box>
        </Box>
    )
}
