import { Box } from '@mui/material'
import React from 'react'

export default function Footer() {
    const currentYear = new Date().getFullYear()
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                m: 0,
                pt: 1,
                backgroundColor: 'background.default',
                position: 'absolute',
                bottom: 0,
                minHeight: '5rem',
                width: '100%',
                zIndex: 2,
            }}
        >
            <footer className="Footer">
                <span>
                    Aircrew Port Pages
                    <br />
                    Copyright &copy; {currentYear}
                </span>
            </footer>
        </Box>
    )
}
