import { Typography } from '@mui/material'
import React, { useState } from 'react'
import MessageModal from './MessageModal'
import Box from '@mui/material/Box'
import theme from './Theme'

export default function Error({ error }) {
    const [message, setMessage] = useState({
        type: 'Unable to comply...',
        text: error,
        navigateTo: '/',
    })
    return (
        <Box
            sx={{
                minHeight: '80vh',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '40vh',
                }}
            >
                <Typography variant="h4" align="center" color={theme.palette.error.main}>
                    {error}
                </Typography>
            </Box>
            {message && <MessageModal message={message} setMessage={setMessage} />}
        </Box>
    )
}
