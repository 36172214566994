// import {  makeRequest } from "./makeRequest"
import baseURL from "../api/api"

export function getPorts({ axiosPrivate, token }) {
    return axiosPrivate(`${baseURL}/ports`, {
        method: "GET",
        headers: { authorization: `Bearer ${token}`},
    })
  }

  export function createPort({ axiosPrivate, port, token, name, place_id, country, lat, lng }) {
    return axiosPrivate(`${baseURL}/ports`, {
      method: "POST",
      headers: { authorization: `Bearer ${token}`},
      data: { name, place_id, port, country, lat, lng },
    })
  }