import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading({ error }) {
    return (
        <Box
            sx={{
                minHeight: '80vh',
                width: '100%',
            }}
        >
            <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '40vh'

                
            }}
            >
                <CircularProgress />
            </Box>
        </Box>
    )
}