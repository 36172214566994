import Box from "@mui/material/Box"
// import Typography from "@mui/material/Typography"
import React from "react"
import AircrewPortPages_title from '../../assets/AircrewPortPages_title.png'

export default function Header() {
      

    return (
            <Box
            sx={{
                // alignItems: 'flex-start',
                // justifyItems: 'end',
                // justifyContent: 'flex-end',
                backgroundColor: "background.default", 
                height: {xs: '10vh',sm: '15vh'}, 
                width: '100%',
                // p:6,
                }}>
            <header style={{height: '100%', width: '100%'}}>
                <Box  sx={{ 
                    display: 'flex', 
                    height: '100%', 
                    width: '100%', 
                    justifyContent: {xs: 'center', sm: 'start'}, 
                    pl: 3, 
                    /*alignItems: 'flex-end'*/
                    }}> 
                    <img src={AircrewPortPages_title} height='100%' alt="Aircrew Port Pages Logo"/>
                    {/* <Typography sx={{
                        fontSize: {xs: 38, sm: 50 }
                    }} variant="h3">Aircrew Port Pages</Typography> */}
                </Box>
            </header>
            
            </Box>
    )
}