import { useCallback, useEffect, useState } from 'react'
import { useError } from '../contexts/ErrorContext'

export function useAsync(func, dependencies = []) {
    const { execute, ...state } = useAsyncInternal(func, dependencies, true)
    const { setError } = useError()

    useEffect(
        () => {
            try {
                execute()
            } catch (error) {
                setError(error?.response?.data?.message || `Error: ${error.message}`)
                // return Promise.reject(error?.response?.data?.message)
            }
        },
        // eslint-disable-next-line
        [execute]
    )

    return state
}

export function useAsyncFn(func, dependencies = []) {
    return useAsyncInternal(func, dependencies, false)
}

function useAsyncInternal(func, dependencies, initialLoading = false) {
    const [loading, setLoading] = useState(initialLoading)
    const { error, setError } = useError()
    const [value, setValue] = useState()

    const execute = useCallback(
        (...params) => {
            setLoading(true)
            return func(...params)
                .then((data) => {
                    setValue(data)
                    setError(false)
                    return data
                })
                .catch((error) => {
                    setValue(undefined)
                    if (error?.response?.status !== 422) {
                        setError(
                            error?.response?.data?.message || `Error: ${error.message}`
                        )
                    } else {
                        return Promise.reject(error?.response?.data?.message || 'Error')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        },
        // eslint-disable-next-line
        [dependencies]
    )

    return { loading, error, value, execute, setError }
}
