import React, { useState } from 'react'
import { Backdrop, Box, Modal, Fade, Typography, IconButton, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation, useNavigate } from 'react-router-dom'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 300, sm: 400 },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function MessageModal({ message, setMessage }) {
    const [open, setOpen] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()

    const handleClose = async () => {
        setOpen(false)
        if (message.navigateTo) {
            await navigate(
                message.navigateTo,
                { state: message.replace !== false ? { from: location } : null },
                { replace: message.replace || true }
            )
            // window.location.reload();
        }
        setMessage(null)
    }

    return (
        <Box
            component="dialog"
            sx={{ width: '100%' }}
            // onKey={handleClose}
        >
            <Modal
                sx={{ width: '100%' }}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton onClick={handleClose} autoFocus>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography
                            id="transition-modal-title"
                            /*variant='h5'*/ sx={{ fontSize: { xs: 25, sm: 35 } }}
                        >
                            {message.type}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            {message.text}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 3,
                                width: '100%',
                            }}
                        >
                            <Button
                                sx={{ width: '100%' }}
                                variant="outlined"
                                onClick={handleClose}
                            >
                                CLOSE
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    )
}
