import React, { createContext, useContext, useState } from 'react'

const Context = createContext()

export function useAuth() {
    if (!Context) {
        throw new Error('Component not wrapped in context')
    }
    return useContext(Context)
}

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState({})
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem('persist')) || false
    )

    function removeAuth() {
        setAuth({})
    }

    return (
        <Context.Provider
            value={{
                auth,
                setAuth,
                removeAuth,
                persist,
                setPersist,
            }}
        >
            {children}
        </Context.Provider>
    )
}
