// import { useLoadScript } from "@react-google-maps/api";
import { Loader } from '@googlemaps/js-api-loader'
import { useEffect, useRef, useState } from 'react'

const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
})

export default function useMap(options) {
    const map = useRef()
    const [isLoaded, setIsLoaded] = useState(false)
    const [loadError, setLoadError] = useState(false)
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    // })
    useEffect(() => {
        loader
            .load()
            .then(() => {
                if (loader.status === 2) {
                    setIsLoaded(true)
                }
            })
            .catch((error) => {
                setLoadError(true)
            })
    }, [options])

    function deleteScript() {
        loader.deleteScript()
    }

    return { isLoaded, loadError, deleteScript, map }
}
