// import { makePrivateRequest, makeRequest } from "./makeRequest"
import baseURL from "../api/api"

export function getData({ axiosPrivate, port, token }) {
    // *** This needs to be changed to token
    return axiosPrivate(`${baseURL}/${port}/data`, {
        method: "GET",
        headers: { authorization: `Bearer ${token}`},
    })
  }

  export function createData({ axiosPrivate, port, token, name, place_id, category, cost, details, lat, lng }) {
    return axiosPrivate(`${baseURL}/${port}/data/add`, {
      method: "POST",
      headers: { authorization: `Bearer ${token}`},
      data: { name, place_id, category, cost, details, lat, lng },
    })
  }

  export function updateData({ axiosPrivate, token, port, data_id, category, cost, details}) {
    return axiosPrivate(`${baseURL}/${port}/data/${data_id}/update`, {
      method: "PUT",
      headers: { authorization: `Bearer ${token}`},
      data: { category, cost, details },

    })
  }
    

  export function toggleDataLike({ axiosPrivate, port, token, data_id, likedStatus }) {
    return axiosPrivate(`${baseURL}/${port}/data/${data_id}/toggleLike`, {
      method: "POST",
      headers: { authorization: `Bearer ${token}`},
      data: { likedStatus },
    })
  }

  export function deleteData({ axiosPrivate, port, data_id, token }) {
    return axiosPrivate(`${baseURL}/${port}/data/${data_id}/delete`, {
      method: "DELETE",
      headers: { authorization: `Bearer ${token}`},
    })
  }