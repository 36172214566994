import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import MessageModal from '../../components/MessageModal'
import baseURL from '../../api/api'

export default function Cancel() {
    // Here the user has been redirected by stripe by cancelling the subscription process

    const [message, setMessage] = useState(null)
    const urlSearchParams = new URLSearchParams(window.location.search)
    const token = urlSearchParams.get('token')

    useEffect(() => {
        let active = true
        if (active) {
            fetch(`${baseURL}/users/cancelRegistration`, {
                method: 'DELETE',
                headers: {
                    accept: 'application/json',
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json()
                    }
                    return res.json().then((json) => {
                        return Promise.reject(json)
                    })
                })
                .then((data) => {
                    setMessage({
                        type: 'Go Around!',
                        text: data.message,
                        navigateTo: '/',
                        replace: false,
                    })
                })
                .catch((err) => {
                    setMessage({
                        type: 'Cancel Error',
                        text: `An error occured while trying to cancel the registration process: ${err.message}`,
                    })
                })
        }

        return () => {
            active = false
        }
    }, [token])

    return (
        <Box sx={{ minHeight: '70vh' }}>
            {message && <MessageModal message={message} setMessage={setMessage} />}
        </Box>
    )
}
