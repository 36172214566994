import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import theme from './components/Theme'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext'
import { ErrorProvider } from './contexts/ErrorContext'
import { UserLocationProvider } from './contexts/UserLocationContext'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

if (process.env.NODE_ENV === 'production') {
    disableReactDevTools()
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    //   <React.StrictMode>
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <CssBaseline />
            <AuthProvider>
                <Routes>
                    <Route
                        path="/*"
                        element={
                            <ErrorProvider>
                                <UserLocationProvider>
                                    <App />
                                    <ToastContainer theme="dark" />
                                </UserLocationProvider>
                            </ErrorProvider>
                        }
                    />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    </ThemeProvider>
    //   </React.StrictMode>
)
