import { axiosPrivate } from '../services/makeRequest'
import { useEffect } from 'react'
import useRefreshToken from './useRefreshToken'
import { useAuth } from '../contexts/AuthContext'

export default function useAxiosPrivate() {
    const refresh = useRefreshToken()
    const { auth } = useAuth()
    const token = auth?.token

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config) => {
                if (!config.headers['authorization']) {
                    config.headers['authorization'] = `Bearer ${token}`
                }
                return config
            },
            (error) => Promise.reject(error)
        )

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => response,
            async (error) => {
                const prevRequest = error?.config
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true
                    const newAccessToken = await refresh()
                    prevRequest.headers['authorization'] = `Bearer ${newAccessToken}`
                    return axiosPrivate(prevRequest)
                }

                return Promise.reject(error)
            }
        )

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept)
            axiosPrivate.interceptors.response.eject(responseIntercept)
        }
    }, [token, refresh])

    return axiosPrivate
}
