import Box from '@mui/system/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import AirlinesIcon from '@mui/icons-material/Airlines'
import EmailIcon from '@mui/icons-material/Email'
import BadgeIcon from '@mui/icons-material/Badge'
import Grid from '@mui/material/Unstable_Grid2'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import UserAvatar from '../../components/navbar/UserAvatar'
import MessageModal from '../../components/MessageModal'
import DeleteProfile from './DeleteProfile'
import useNetwork from '../../hooks/useNetwork'
import baseURL from '../../api/api'
import ManageSubscription from './ManageSubscription'
import ReinstateSubscription from './ReinstateSubscription'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import { useAuth } from '../../contexts/AuthContext'
import { CircularProgress } from '@mui/material'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { makePrivateRequest } from '../../services/makeRequest'
import { useError } from '../../contexts/ErrorContext'

export default function Profile() {
    const [userData, setUserData] = useState({
        firstName: ' ',
        lastName: ' ',
        airline_id: ' ',
        staffNumber: ' ',
        mobile: ' ',
        email: ' ',
        status: ' ',
    })

    const [disabled, setDisabled] = useState(true)
    const [editDisabled, setEditDisabled] = useState(false)
    const [message, setMessage] = useState(null)
    const { error, setError } = useError()
    const [loading, setLoading] = useState(false)
    const isOnline = useNetwork()
    const theme = useTheme()
    const bigScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const { auth, setAuth } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    const handleChange = (prop) => (event) => {
        setUserData({ ...userData, [prop]: event.target.value })
    }

    function handleClick() {
        setDisabled(false)
        setEditDisabled(true)
    }

    function handleSave() {
        if (!userData.firstName.trim() || !userData.lastName.trim()) {
            if (!userData.firstName.trim()) {
                setMessage({
                    type: 'Entry Error',
                    text: 'First Name field requires text entry',
                })
            }
            if (!userData.lastName.trim()) {
                setMessage({
                    type: 'Entry Error',
                    text: 'Last Name field requires text entry',
                })
            }
            return
        }
        setDisabled(true)
        const controller = new AbortController()
        const signal = controller.signal
        makePrivateRequest(`${baseURL}/users/update`, {
            method: 'POST',
            signal,
            data: {
                firstName: capitalizeFirstLetter(userData.firstName.trim()),
                lastName: capitalizeFirstLetter(userData.lastName.trim()),
                staffNumber: userData.staffNumber.trim(),
                mobile: userData.mobile ? userData.mobile.trim() : null,
            },
        })
            .then((userInfo) => {
                setUserData((prev) => ({
                    ...prev,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    staffNumber: userInfo.staffNumber,
                    mobile: userInfo.mobile,
                }))
                setAuth((prev) => ({
                    ...prev,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                }))
                setEditDisabled(false)
            })
            .catch((err) => {
                setMessage({
                    type: 'Profile Update Error',
                    text: err,
                })
                setError(err)
                setEditDisabled(false)
            })
        return () => {
            controller.abort()
        }
    }

    useEffect(() => {
        let active = true
        const controller = new AbortController()
        const signal = controller.signal
        const url = `${baseURL}/users/profile`
        async function getProfile() {
            try {
                setLoading(true)
                const response = await axiosPrivate.get(url, signal)
                active &&
                    setUserData((prev) => ({
                        ...prev,
                        firstName: response?.data?.user.firstName,
                        lastName: response?.data?.user.lastName,
                        staffNumber: response?.data?.user.staffNumber,
                        email: response?.data?.user.email,
                        mobile: response?.data?.user.mobile
                            ? response?.data?.user.mobile
                            : '',
                        status: response?.data?.user.status,
                        airline_id: response?.data?.user.airline_id,
                    }))
                setLoading(false)
            } catch (error) {
                setError(error?.response?.data?.message || 'Unknown Error')
                setLoading(false)
            }
        }

        getProfile()

        return () => {
            active = false
            controller.abort()
        }
    }, [auth?.token, axiosPrivate, setError])

    function capitalizeFirstLetter(string) {
        if (!string || string === '') return undefined
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    return (
        <Box sx={{ minHeight: '80vh', width: '100%' }}>
            {loading ? (
                <Loading />
            ) : error ? (
                <Error error={error} />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{ mb: 3, width: '100%', textAlign: 'center' }}
                        variant="h4"
                    >
                        {`${auth?.firstName} ${auth?.lastName}`}
                    </Typography>

                    <Box
                        display="flex"
                        alignItems="center"
                        width="100%"
                        justifyContent="space-around"
                        flexBasis="content"
                    >
                        <Grid
                            container
                            spacing={2}
                            columns={{ xs: 6, sm: 12 }}
                            sx={{ width: { xs: '80vw', sm: '60vw' } }}
                        >
                            <Grid xs={6} sm={6}>
                                <TextField
                                    required
                                    label="First Name"
                                    id="firstName"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon
                                                    sx={{
                                                        color: disabled
                                                            ? 'text.disabled'
                                                            : 'text.primary',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={userData.firstName}
                                    onChange={handleChange('firstName')}
                                    disabled={disabled}
                                    error={!userData.firstName}
                                />
                            </Grid>

                            <Grid xs={6} sm={6}>
                                <TextField
                                    required
                                    label="Last Name"
                                    id="lastName"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon
                                                    sx={{
                                                        color: disabled
                                                            ? 'text.disabled'
                                                            : 'text.primary',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={userData.lastName}
                                    onChange={handleChange('lastName')}
                                    disabled={disabled}
                                    error={!userData.lastName}
                                />
                            </Grid>

                            <Grid xs={6} sm={6}>
                                <TextField
                                    required
                                    label="Airline"
                                    id="airline_id"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AirlinesIcon
                                                    sx={{
                                                        color: 'text.disabled',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={userData.airline_id}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid xs={6} sm={6}>
                                <TextField
                                    label="Staff Number"
                                    id="staffNumber"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <BadgeIcon
                                                    sx={{
                                                        color: disabled
                                                            ? 'text.disabled'
                                                            : 'text.primary',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={userData.staffNumber}
                                    onChange={handleChange('staffNumber')}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid xs={6} sm={6}>
                                <TextField
                                    label="Mobile"
                                    id="mobile"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SmartphoneIcon
                                                    sx={{
                                                        color: disabled
                                                            ? 'text.disabled'
                                                            : 'text.primary',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={userData.mobile ? userData.mobile : ''}
                                    onChange={handleChange('mobile')}
                                    disabled={disabled}
                                />
                            </Grid>

                            <Grid xs={6} sm={6}>
                                <TextField
                                    required
                                    label="Subscription Status"
                                    id="status"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SubscriptionsIcon
                                                    sx={{
                                                        color: 'text.disabled',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={capitalizeFirstLetter(userData.status)}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid xs={12} sm={12}>
                                <TextField
                                    required
                                    label="Email"
                                    id="email"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon
                                                    sx={{
                                                        color: 'text.disabled',
                                                    }}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    fullWidth
                                    value={userData.email}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid xs={12} sm={12}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: theme.palette.text.disabled,
                                    }}
                                >
                                    * = required
                                </Typography>
                            </Grid>
                        </Grid>
                        {bigScreen && (
                            <Box
                                sx={{
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                }}
                            >
                                <UserAvatar
                                    firstName={auth?.firstName}
                                    lastName={auth?.lastName}
                                />
                            </Box>
                        )}
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        {disabled
                            ? isOnline && (
                                  <Box sx={{ width: '100%' }}>
                                      <Button
                                          sx={{
                                              width: {
                                                  xs: '100%',
                                                  sm: '230px',
                                              },
                                          }}
                                          variant="outlined"
                                          disabled={editDisabled}
                                          onClick={handleClick}
                                          startIcon={
                                              editDisabled ? (
                                                  <CircularProgress size={20} />
                                              ) : (
                                                  <EditIcon />
                                              )
                                          }
                                      >
                                          EDIT PROFILE
                                      </Button>
                                  </Box>
                              )
                            : isOnline && (
                                  <Box sx={{ width: '100%' }}>
                                      <Button
                                          sx={{
                                              width: {
                                                  xs: '100%',
                                                  sm: '170px',
                                              },
                                          }}
                                          variant="outlined"
                                          onClick={handleSave}
                                          startIcon={<SaveIcon />}
                                      >
                                          SAVE PROFILE
                                      </Button>
                                  </Box>
                              )}
                        {isOnline &&
                            (userData.status !== 'canceled' ? (
                                <ManageSubscription />
                            ) : (
                                <ReinstateSubscription />
                            ))}
                        <DeleteProfile />
                        {message && (
                            <MessageModal message={message} setMessage={setMessage} />
                        )}
                    </Box>
                </Box>
            )}
        </Box>
    )
}
