import React, { createContext, useContext, useState } from 'react'

const Context = createContext()

export function useError() {
    if (!Context) {
        throw new Error('Component not wrapped in context')
    }
    return useContext(Context)
}

export function ErrorProvider({ children }) {
    const [error, setError] = useState(false)

    return (
        <Context.Provider
            value={{
                error,
                setError,
            }}
        >
            {children}
        </Context.Provider>
    )
}
