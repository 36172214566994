// import { makeRequest } from "./makeRequest"
import baseURL from "../api/api"

export function getComments({ axiosPrivate, data_id, token }) {
    // *** This needs to be changed to token
    return axiosPrivate(`${baseURL}/${data_id}/comments`, {
        method: "GET",
        headers: { authorization: `Bearer ${token}`}
    })
  }

export function createComment({ axiosPrivate, data_id, comment, parent_id, token }) {
  return axiosPrivate(`${baseURL}/${data_id}/comments/add`, {
    method: "PUT",
    headers: { authorization: `Bearer ${token}`},
    data: { comment, parent_id },
  })
}

export function updateComment({ axiosPrivate, token, data_id, comment, comment_id }) {
  return axiosPrivate(`${baseURL}/${data_id}/comments/${comment_id}`, {
    method: "PUT",
    headers: { authorization: `Bearer ${token}`},
    data: { comment },
  })
}

export function deleteComment({ axiosPrivate, token, data_id, comment_id }) {
  return axiosPrivate(`${baseURL}/${data_id}/comments/${comment_id}`, {
    method: "DELETE",
    headers: { authorization: `Bearer ${token}`},
  })
}

export function toggleCommentLike({ axiosPrivate, token, comment_id, data_id }) {
  return axiosPrivate(`${baseURL}/${data_id}/comments/${comment_id}/toggleLike`, {
    method: "POST",
    headers: { authorization: `Bearer ${token}`},
  })
}
