import { useState } from 'react'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import React from 'react'
import { Button, CircularProgress, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Chip from '@mui/material/Chip'
import baseURL from '../../api/api'
import Skeleton from '@mui/material/Skeleton'
import MessageModal from '../../components/MessageModal'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'

export default function PricingTable({
    subscriptionsData,
    loading,
    setDisabled,
    token,
    reinstate,
}) {
    const data = handleData(subscriptionsData)
    const [duration, setDuration] = useState('month')
    const [chosenSubId, setChosenSubId] = useState(data[duration][0]?.id)
    const testMode = process.env.NODE_ENV !== 'production'
    const saveAmount = (data.month[0].unit_amount * 12 - data.year[0].unit_amount) / 100
    const { reinstateAccount, prevDeletedUser } = reinstate
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [message, setMessage] = useState(null)

    const subscriptionIncludes = [
        '30 day free initial trial',
        'View/ Create / Like activities, places to eat, shop etc.',
        'For every entry added, recieve A$0.25 credit to your account',
        'View/ Create/ Like comments',
        'Check-in to ports and see others who have checked in',
        'Cancel at any time',
    ]

    function handleData(data) {
        const group = {}
        data.forEach((dataItem) => {
            group[dataItem.recurring.interval] ||= []
            group[dataItem.recurring.interval].push(dataItem)
        })
        return group
    }

    const handleChange = (event, newDuration) => {
        if (newDuration !== null) {
            setDuration(newDuration)
            setChosenSubId(data[newDuration][0].id)
        }
    }

    function handleClick() {
        setDisabled(true)
        setCheckoutLoading(true)
        fetch(`${baseURL}/stripe/create-checkout-session`, {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                price: chosenSubId,
                reinstateAccount: reinstateAccount,
                prevDeletedUser: prevDeletedUser,
            }),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }
                return res.json().then((json) => {
                    return Promise.reject(json)
                })
            })
            .then(({ url }) => {
                window.location = url
            })
            .catch((e) => {
                setMessage({
                    type: 'Checkout Error',
                    text: `Error creating checkout session: ${e.message}`,
                })
            })
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {!loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ToggleButtonGroup
                        sx={{ m: 1 }}
                        color="primary"
                        value={duration}
                        exclusive
                        onChange={handleChange}
                        aria-label="Subsciption Duration"
                    >
                        <ToggleButton value="month">Monthly</ToggleButton>
                        <ToggleButton value="year">Yearly</ToggleButton>
                    </ToggleButtonGroup>
                    {testMode && (
                        <Chip
                            sx={{ display: 'flex', alignSelf: 'flex-start' }}
                            label="TEST MODE"
                            color="warning"
                            size="small"
                        />
                    )}
                    <Typography sx={{ mt: 2, mb: 2, textAlign: 'center' }} variant="h6">
                        Aircrew Port Pages Subscription Plan
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                            A${data[duration][0]?.unit_amount / 100}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', pl: '3px' }}>
                            <Typography variant="caption" color="darkgray">
                                per
                            </Typography>
                            <Typography variant="caption" color="darkgray">
                                {duration}
                            </Typography>
                        </Box>
                        {duration === 'year' ? (
                            <Chip
                                sx={{ display: 'flex', alignSelf: 'flex-start', m: 1 }}
                                label={`Save A$${saveAmount}`}
                                color="warning"
                                size="small"
                            />
                        ) : null}
                    </Box>
                    <Button
                        sx={{ mb: 2, mt: 2, width: '100%' }}
                        variant="contained"
                        onClick={handleClick}
                        disabled={checkoutLoading}
                        startIcon={
                            checkoutLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <ShoppingCartCheckoutIcon />
                            )
                        }
                    >
                        CHECKOUT
                    </Button>
                    {!reinstateAccount && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'flex-start',
                            }}
                        >
                            <Typography sx={{ mb: 1 }} variant="body2">
                                This includes:
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                                {subscriptionIncludes.map((item, index) => {
                                    return (
                                        <Box
                                            sx={{ display: 'flex', pb: 0.5 }}
                                            key={index}
                                        >
                                            <CheckCircleIcon fontSize="small" />
                                            <Typography sx={{ ml: 1 }} variant="body2">
                                                {item}
                                            </Typography>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rectangular" width={210} height={60} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Box>
            )}
            {message && <MessageModal message={message} setMessage={setMessage} />}
        </Box>
    )
}
