import { Button, CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MessageModal from '../../components/MessageModal'
import useNetwork from '../../hooks/useNetwork'
import baseURL from '../../api/api'
import { useAuth } from '../../contexts/AuthContext'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useError } from '../../contexts/ErrorContext'

export default function DeleteProfile() {
    const [open, setOpen] = useState(false)
    const { removeAuth } = useAuth()
    const [message, setMessage] = useState(null)
    const isOnline = useNetwork()
    const [deleteDisabled, setDeleteDisabled] = useState(false)
    const axiosPrivate = useAxiosPrivate()
    const { setError } = useError()

    function handleClickDelete() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }

    function handleDeleteConfirmed() {
        setDeleteDisabled(true)
        const url = `${baseURL}/users/deleteUser`
        const controller = new AbortController()
        const signal = controller.signal
        // Delete user from DB
        axiosPrivate
            .delete(url, signal)
            .then((res) => {
                removeAuth()
                localStorage.clear()
                sessionStorage.clear()
                setMessage({
                    type: `Sarwatch Terminated`,
                    text: 'Your profile information has been removed. Sorry to see you go.',
                    navigateTo: '/',
                    replace: false,
                })
                setDeleteDisabled(false)
            })
            .catch((error) => {
                // setMessage({
                //     type: 'Profile Error',
                //     text: `Tried to delete profile. Error: ${error?.response?.data?.message}`,
                // })
                setError(error?.response?.data?.message)
                setDeleteDisabled(false)
            })
            .finally(() => {
                setOpen(false)
            })
        return controller.abort()
    }

    const dialogText = `Warning! This action is permanent and will cancel any trial or subscription immediately. You will no longer have access to Aircrew Port Pages. If you would like to cancel your subscription or trial but still have access until the specified period ends, select Manage Subscription -> Cancel Subscription. Again, this action cannot be undone...`

    return (
        <Box sx={{ width: '100%' }}>
            {isOnline && (
                <Button
                    sx={{
                        width: { xs: '100%', sm: '170px' },
                    }}
                    color="error"
                    onClick={handleClickDelete}
                    variant="outlined"
                    disabled={deleteDisabled}
                    startIcon={
                        deleteDisabled ? (
                            <CircularProgress size={20} />
                        ) : (
                            <DeleteForeverIcon />
                        )
                    }
                >
                    DELETE PROFILE
                </Button>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Delete Profile?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        CANCEL
                    </Button>
                    <Button
                        color="error"
                        variant="contained"
                        startIcon={<DeleteForeverIcon />}
                        onClick={handleDeleteConfirmed}
                    >
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
            {message && <MessageModal message={message} setMessage={setMessage} />}
        </Box>
    )
}
