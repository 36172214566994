import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext";

export default function RequireAuth() {
    const { auth } = useAuth()
    const location = useLocation()

    return (
        auth?.token
            ? <Outlet />
            // : auth?.user
                // ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/" state={{ from: location }} replace />
    );
}