import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import React, { useState, useEffect } from 'react'
import { CircularProgress, Dialog, DialogActions, DialogTitle } from '@mui/material'
import baseURL from '../../api/api'
import PricingTable from '../Login/PricingTable'
import { useAuth } from '../../contexts/AuthContext'
import MessageModal from '../../components/MessageModal'

/*
*********************************************
If the user has cancelled their subscription,
but have not deleted their profile, this allows
the user to reactivate a subscription.
*********************************************
*/

export default function ReinstateSubscription() {
    const { auth } = useAuth()
    const [message, setMessage] = useState(null)
    const token = auth?.token
    const [disabled, setDisabled] = useState(false)
    const [priceData, setPriceData] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const reinstate = {
        reinstateAccount: true,
        prevDeletedUser: false,
    }

    function handleClick() {
        setDisabled(true)
        setOpen(true)
    }

    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
        fetch(`${baseURL}/stripe/get-subscriptions`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            signal,
        })
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }
                return res.json().then((json) => {
                    return Promise.reject(json)
                })
            })
            .then((data) => {
                setPriceData(data.price_data)
                setLoading(false)
            })
            .catch((error) => {
                setMessage({
                    type: 'unable...',
                    text: `Could not get required data from server: ${error.message}`,
                })
            })

        return () => {
            controller.abort()
        }
    }, [token])

    function handleCancel() {
        setOpen(false)
        setDisabled(false)
    }

    return (
        <Box>
            <Button
                sx={{
                    width: { xs: '100%', sm: '230px' },
                    mt: { xs: 2, sm: 0 },
                    mb: { xs: 2, sm: 0 },
                    ml: { xs: 0, sm: 2 },
                    mr: { xs: 0, sm: 2 },
                }}
                variant="outlined"
                startIcon={
                    !disabled ? <SubscriptionsIcon /> : <CircularProgress size={20} />
                }
                onClick={handleClick}
                disabled={disabled}
            >
                NEW SUBSCRIPTION
            </Button>
            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Reinstate Subcription</DialogTitle>
                <DialogActions>
                    <PricingTable
                        subscriptionsData={priceData}
                        loading={loading}
                        setDisabled={setDisabled}
                        token={token}
                        reinstate={reinstate}
                    />
                </DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
            </Dialog>
            {message && <MessageModal message={message} setMessage={setMessage} />}
        </Box>
    )
}
