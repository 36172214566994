import React, { useContext, useEffect, useState, useMemo, createContext } from 'react'
import { useParams } from 'react-router-dom'
import { useAsync } from '../hooks/useAsync'
import { getData } from '../services/data'
import Loading from '../components/Loading'
import Error from '../components/Error'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useAuth } from './AuthContext'
import { useError } from './ErrorContext'

const Context = createContext()

export function useData() {
    return useContext(Context)
}

export function DataProvider({ children }) {
    const { auth } = useAuth()
    const token = auth?.token
    const { code } = useParams()
    const axiosPrivate = useAxiosPrivate()
    const { error } = useError()

    const {
        loading,
        // error,
        value: dataInfo,
    } = useAsync(() => getData({ axiosPrivate, port: code, token: token }), code)
    const [data, setData] = useState([])
    const dataById = useMemo(() => {
        const group = {}
        data.forEach((dataItem) => {
            group[dataItem.data_id] ||= []
            group[dataItem.data_id].push(dataItem)
        })
        return group
    }, [data])

    useEffect(() => {
        if (dataInfo?.data?.data == null) return
        setData(dataInfo?.data?.data)
    }, [dataInfo?.data?.data])

    function getDataItem(data_id) {
        return dataById[data_id]
    }

    function modifyDataProperties(data_id, value) {
        setData((prevData) => {
            return prevData.map((prevDataItem) => {
                if (prevDataItem.data_id === data_id) {
                    return { ...prevDataItem, distanceFromUser: value }
                } else {
                    return prevDataItem
                }
            })
        })
    }

    function createLocalData(data) {
        setData((prevData) => {
            return [data, ...prevData]
        })
    }

    function updateLocalData(data) {
        setData((prevData) => {
            return prevData.map((prevDataItem) => {
                if (prevDataItem.data_id === data?.data?.data_id) {
                    return {
                        ...prevDataItem,
                        category: data?.data?.category,
                        cost: data?.data?.cost,
                        details: data?.data?.details,
                    }
                } else {
                    return prevDataItem
                }
            })
        })
    }

    function deleteLocalData(data_id) {
        setData((prevData) => {
            return prevData.filter((data) => data.data_id !== parseInt(data_id))
        })
    }

    function toggleLocalDataLike(data_id, addLike) {
        setData((prevData) => {
            return prevData.map((data) => {
                if (data_id === data.data_id) {
                    if (addLike === true) {
                        if (data.likedByMe === false) {
                            return {
                                ...data,
                                likeCount: data.likeCount + 2,
                                likedByMe: true,
                            }
                        } else {
                            return {
                                ...data,
                                likeCount: data.likeCount + 1,
                                likedByMe: true,
                            }
                        }
                    } else if (addLike === false) {
                        if (data.likedByMe === true) {
                            return {
                                ...data,
                                likeCount: data.likeCount - 2,
                                likedByMe: false,
                            }
                        } else {
                            return {
                                ...data,
                                likeCount: data.likeCount - 1,
                                likedByMe: false,
                            }
                        }
                    } else {
                        if (data.likedByMe === true) {
                            // true going to null
                            return {
                                ...data,
                                likeCount: data.likeCount - 1,
                                likedByMe: null,
                            }
                        } else {
                            // false going to null
                            return {
                                ...data,
                                likeCount: data.likeCount + 1,
                                likedByMe: null,
                            }
                        }
                    }
                } else {
                    return data
                }
            })
        })
    }

    return (
        <Context.Provider
            value={{
                dataInfo: { code, data },
                getDataItem,
                createLocalData,
                updateLocalData,
                deleteLocalData,
                toggleLocalDataLike,
                modifyDataProperties,
            }}
        >
            {loading ? <Loading /> : error ? <Error error={error.message} /> : children}
        </Context.Provider>
    )
}
