import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useAsync } from '../hooks/useAsync'
import { getComments } from '../services/comments'
import { useSearchParams } from 'react-router-dom'
import Loading from '../components/Loading'
import Error from '../components/Error'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useAuth } from './AuthContext'
import { useError } from './ErrorContext'

const Context = React.createContext()

export function usePost() {
    return useContext(Context)
}

export function CommentProvider({ children }) {
    const [searchParams] = useSearchParams()
    const { auth } = useAuth()
    const token = auth?.token
    const axiosPrivate = useAxiosPrivate()
    const { error } = useError()

    const data_id = searchParams.get('id')
    const {
        loading,
        // error,
        value: post,
    } = useAsync(
        () => getComments({ axiosPrivate, data_id: data_id, token: token }),
        data_id
    )
    const [comments, setComments] = useState([])
    const commentsByParent_id = useMemo(() => {
        const group = {}
        comments.forEach((comment) => {
            group[comment.parent_id] ||= []
            group[comment.parent_id].push(comment)
        })
        return group
    }, [comments])

    useEffect(() => {
        if (post?.data?.comments == null) return
        setComments(post?.data?.comments)
    }, [post?.data?.comments])

    function getReplies(parent_id) {
        return commentsByParent_id[parent_id]
    }

    function createLocalComment(comment) {
        if (comment) {
            setComments((prevComments) => {
                return [comment, ...prevComments]
            })
        }
        return
    }

    function updateLocalComment(comment_id, comment) {
        setComments((prevComments) => {
            return prevComments.map((prevComment) => {
                if (prevComment.comment_id === comment_id) {
                    return { ...prevComment, comment }
                } else {
                    return prevComment
                }
            })
        })
    }

    function deleteLocalComment(comment_id) {
        setComments((prevComments) => {
            return prevComments.filter(
                (comment) => comment.comment_id !== parseInt(comment_id)
            )
        })
    }

    function toggleLocalCommentLike(comment_id, addLike) {
        setComments((prevComments) => {
            return prevComments.map((comment) => {
                if (comment_id === comment.comment_id) {
                    if (addLike) {
                        return {
                            ...comment,
                            likeCount: comment.likeCount + 1,
                            likedByMe: true,
                        }
                    } else {
                        return {
                            ...comment,
                            likeCount: comment.likeCount - 1,
                            likedByMe: false,
                        }
                    }
                } else {
                    return comment
                }
            })
        })
    }

    return (
        <Context.Provider
            value={{
                post: { data_id, ...post },
                rootComments: commentsByParent_id[null],
                getReplies,
                createLocalComment,
                updateLocalComment,
                deleteLocalComment,
                toggleLocalCommentLike,
            }}
        >
            {loading ? <Loading /> : error ? <Error error={error.message} /> : children}
        </Context.Provider>
    )
}
