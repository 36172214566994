import { createContext, useContext, useEffect, useState } from 'react'

const Context = createContext()

export function useUserLocation() {
    if (!Context) {
        throw new Error('Component not wrapped in context')
    }
    return useContext(Context)
}

export function UserLocationProvider({ children }) {
    const [userLocation, setUserLocation] = useState({
        permission: '',
        error: false,
        loaded: false,
        accuracy: null,
        coordinates: { lat: 0, lng: 0 },
    })

    function onSuccess(location) {
        setUserLocation((prev) => ({
            ...prev,
            loaded: true,
            permission: 'granted',
            accuracy: location.coords.accuracy,
            coordinates: {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
            },
        }))
    }

    function onError(error) {
        if (error === 'Permission not granted') {
            return setUserLocation((prev) => ({ ...prev, loaded: true, error: false }))
        }
        setUserLocation((prev) => ({
            ...prev,
            loaded: true,
            error: `${
                typeof error === 'string' ? error : error.message
            }. Check your browser location settings`,
        }))
    }

    function getPermission() {
        if (navigator.geolocation) {
            setUserLocation((prev) => ({ ...prev, loaded: false, permission: null }))
            return navigator.geolocation.getCurrentPosition(onSuccess, onError)
        }
    }

    useEffect(() => {
        getUserLocation()
            .then((location) => {
                onSuccess(location)
            })
            .catch((error) => {
                onError(error)
            })
            .finally(() => setUserLocation((prev) => ({ ...prev, loaded: true })))
    }, [])

    function getUserLocation(options) {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.permissions
                    .query({ name: 'geolocation' })
                    .then((permission) => {
                        setUserLocation((prev) => ({
                            ...prev,
                            permission: permission.state,
                        }))
                        if (permission.state === 'prompt') {
                            reject('Permission not granted')
                        }
                        if (permission.state === 'granted') {
                            navigator.geolocation.getCurrentPosition(
                                resolve,
                                reject,
                                options
                            )
                        }
                        if (permission.state === 'denied') {
                            reject('User denied Geolocation')
                        }
                    })
            }
        })
    }

    return (
        <Context.Provider
            value={{
                userLocation,
                getPermission,
            }}
        >
            {children}
        </Context.Provider>
    )
}
