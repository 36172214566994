import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useAsync } from '../hooks/useAsync'
import { getPorts } from '../services/ports'
import Error from '../components/Error'
import Loading from '../components/Loading'
import useMap from '../hooks/useMap'
import Profile from '../pages/Profile/Profile'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useAuth } from './AuthContext'
import { useError } from './ErrorContext'

const Context = createContext()

export function usePorts() {
    return useContext(Context)
}

export function PortsProvider({ children }) {
    // This turns off retrieving photos from google
    const photos = false
    const { auth } = useAuth()
    const token = auth?.token
    const axiosPrivate = useAxiosPrivate()
    const {
        loading,
        // error,
        value: portsData,
    } = useAsync(() => getPorts({ token, axiosPrivate }), '')
    const [ports, setPorts] = useState([])
    const [photoUrls, setPhotoUrls] = useState([])
    const { isLoaded } = useMap()
    const portsByCode = useMemo(() => {
        const group = {}
        ports.forEach((portItem) => {
            group[portItem?.code] ||= []
            group[portItem?.code].push(portItem)
        })
        return group
    }, [ports])
    const { error } = useError()

    useEffect(() => {
        if (portsData?.data?.ports === undefined || portsData?.data?.ports === null)
            return
        setPorts(portsData?.data?.ports)
    }, [portsData?.data?.ports])

    useEffect(() => {
        let active = true

        if (active) {
            if (isLoaded) {
                if (photoUrls.length === 0) {
                    if (!photos) return
                    Object.values(portsByCode).forEach((port) => {
                        const request = {
                            placeId: port[0]?.place_id,
                            fields: ['photo'],
                        }
                        const service = new window.google.maps.places.PlacesService(
                            document.createElement('div')
                        )

                        return service.getDetails(request, (place, status) => {
                            console.log('Fetched place details for photo')
                            if (
                                status ===
                                    window.google.maps.places.PlacesServiceStatus.OK &&
                                place
                            ) {
                                const randNum = Math.floor(Math.random() * 10)

                                setPhotoUrls((prev) => {
                                    console.log('Retrieved photo via getUrl')
                                    return {
                                        ...prev,
                                        [port[0].code]: place.photos[randNum]?.getUrl({
                                            maxWidth: 210,
                                        }),
                                    }
                                })
                            }
                        })
                    })
                }
            }
        }
        return () => {
            active = false
        }
    }, [portsByCode, isLoaded, photoUrls.length, photos])

    function createLocalPort(port) {
        setPorts((prevPorts) => {
            return [port?.data, ...prevPorts]
        })
    }

    function getPortItem(code) {
        return portsByCode[code]
    }
    return (
        <Context.Provider
            value={{
                portsData: { ports },
                photoUrls: { photoUrls },
                createLocalPort,
                getPortItem,
            }}
        >
            {loading ? (
                <Loading />
            ) : window.location.pathname === '/User%20Profile' ? (
                <Profile token={token} />
            ) : error ? (
                <Error error={error} />
            ) : (
                children
            )}
        </Context.Provider>
    )
}
