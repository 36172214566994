import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import baseURL from '../../api/api'
import { CircularProgress } from '@mui/material'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import MessageModal from '../../components/MessageModal'
import { useError } from '../../contexts/ErrorContext'

export default function ManageSubscription() {
    const [manageDisabled, setManagedDisabled] = useState(false)
    const [message, setMessage] = useState(null)
    const axiosPrivate = useAxiosPrivate()
    const { setError } = useError()

    function handleClick() {
        setManagedDisabled(true)
        axiosPrivate(`${baseURL}/stripe/create-customer-portal-session`, {
            method: 'POST',
            data: {
                prevUrl: window.location.href,
            },
        })
            .then((url) => {
                window.location.href = url?.data?.url
            })
            .catch((e) => {
                setError(
                    `Error creating customer portal session: ${
                        e?.respone?.data?.message || e?.message
                    }`
                )
                // setMessage({
                //     type: 'Unable...',
                //     text: `Error creating customer portal session: ${e.message}`,
                // })
            })
            .finally(() => {
                setManagedDisabled(false)
            })
    }

    return (
        <Box>
            <Button
                sx={{
                    width: { xs: '100%', sm: '224px' },
                    mt: { xs: 2, sm: 0 },
                    mb: { xs: 2, sm: 0 },
                    ml: { xs: 0, sm: 1, md: 3 },
                    mr: { xs: 0, sm: 1, md: 3 },
                }}
                variant="outlined"
                startIcon={
                    manageDisabled ? (
                        <CircularProgress size={20} />
                    ) : (
                        <SubscriptionsIcon />
                    )
                }
                onClick={handleClick}
                disabled={manageDisabled}
            >
                Manage Subscription
            </Button>
            {message && <MessageModal message={message} setMessage={setMessage} />}
        </Box>
    )
}
