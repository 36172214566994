import React from "react";
// import {
//   Breadcrumbs as MUIBreadcrumbs,
//   Link,
//   Typography
// } from "@mui/material";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = (props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const pathnames = location.pathname.split("/").filter((x) => x);
    pathnames.map((pathname, index) => {
        if (pathname.includes('%20')) {
            pathnames[index] = pathname.replace(/%20/g, ' ');
        }       
        if (pathname.includes('%' || '(')) {
            pathnames[index] = pathname.replace(/[()]?%[A-Za-z0-9][A-Za-z0-9]/g, ' ');
        }
                    
        return pathname
    })

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }

    return (
        <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon/>} variant="h6" component="div" sx={{ flexGrow: 1 }} >
        {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
            <Typography key={name} variant='h6'>{capitalize(name)}</Typography>
            ) : (
            <Link color='text.secondary' component="button" variant="h6" underline="always" key={name} onClick={() => navigate(routeTo)}>
                {capitalize(name)}
            </Link>
            );
        })}
        </MUIBreadcrumbs>
    );
};

export default Breadcrumbs;
