import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshToken from '../../hooks/useRefreshToken'
import { useAuth } from '../../contexts/AuthContext'
import { useError } from '../../contexts/ErrorContext'

export default function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const { auth, persist } = useAuth()
    const { setError } = useError()

    useEffect(() => {
        let isMounted = true

        async function verifyRefreshToken() {
            try {
                await refresh()
            } catch (err) {
                setError(err?.response?.data?.message || `Error: ${err.message}`)
            } finally {
                isMounted && setIsLoading(false)
            }
        }

        // persist added here AFTER tutorial video
        // Avoids unwanted call to verifyRefreshToken
        !auth?.token && persist ? verifyRefreshToken() : setIsLoading(false)

        return () => (isMounted = false)
    }, [auth?.token, persist, refresh, setError])

    return <>{!persist ? <Outlet /> : isLoading ? <p>Loading...</p> : <Outlet />}</>
}
