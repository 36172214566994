import { useAuth } from '../contexts/AuthContext'
import { useError } from '../contexts/ErrorContext'
import { api } from '../services/makeRequest'
import { jwtDecode } from 'jwt-decode'

const useRefreshToken = () => {
    const { setAuth } = useAuth()
    const { setError } = useError()

    const refresh = async () => {
        const response = await api.get('/refresh', {
            'Content-Type': 'application/json',
            withCredentials: true,
        })
        const { firstName, lastName } = jwtDecode(response.data.accessToken)
        setAuth((prev) => ({
            ...prev,
            firstName: firstName,
            lastName: lastName,
            token: response.data.accessToken,
        }))
        setError(false)

        return response.data.accessToken
    }
    return refresh
}

export default useRefreshToken
