import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import Header from './header/Header'
import Footer from './footer/Footer'
import BarMenu from './navbar/BarMenu'

export default function Layout() {
    const { auth } = useAuth()
    return (
        <Box
            sx={{
                position: 'relative',
                minHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    pb: '5rem',
                }}
            >
                <Header />
                {auth?.token && <BarMenu />}
                <Outlet />
            </Box>
            <Footer />
        </Box>
    )
}
