import { api } from '../services/makeRequest'
import { useAuth } from '../contexts/AuthContext'

export default function useLogout() {
    // const axiosPrivate = useAxiosPrivate()
    const { removeAuth } = useAuth()

    async function logout() {
        try {
            const response = await api.get('/users/logout', {
                withCredentials: true,
            })
            if (response.status === 204) {
                sessionStorage.clear()
                localStorage.clear()
                removeAuth()
            }
        } catch (err) {
            console.error(err)
        }
    }

    return logout
}
